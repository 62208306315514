<template>
  <div>
    <Header/> 
    <div class="detail_lesson">
        <div class="first-line flex-align-con">
            <div class="number-title">1</div>
            <div class="text-title">給評語</div>
            <div class="flex-align-con" style="flex-grow: 1; height: 100px; justify-content: left;">
                <div class="unlike_btn" :style="{opacity: unlike_btn?1:0.5}" @click="unlike_btns(1)">
                    <img src="../../assets/lesson_done/unlike_btn.png" style="width:100%;height:100%;">
                </div>
                <div class="unlike_btn" :style="{opacity: like_btn?1:0.5}" @click="unlike_btns(2)">
                    <img src="../../assets/lesson_done/like_btn.png" style="width:100%;height:100%;">
                </div>
            </div>
        </div>
        <div class="flex-align-con" style="align-items: flex-start;">
            <div class="secend-line flex-align-con">
                <div class="number-title">2</div>
                <div class="text-title" style="margin-top: 10px;">下一步</div>
            </div>
             <div class="other-btn-con">
                <router-link :to="{path:'/',}">
                    <button style="margin-top: 0px;" class="clear-btn round-btn big-button button_s"><img src="../../assets/lesson_done/target_icon.png" class="lesson_img">回到
                        <div style="font-weight: bold; margin-left: 3px;">首頁</div>
                    </button>
                </router-link>
            </div>
        </div>
    </div>
  </div>
</template>

<style>
.unlike_btn{
    height: 15vh;
    width: 15vh;
    margin-left: 5vw;
}
.lesson_img{
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: inline-block;
    overflow: hidden;
    position: relative;
}
.button_s{
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    line-height: 2.55555556;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    color: #000;
    background-color: #f8f8f8;
    cursor: pointer;
}
.clear-btn {
    display: inline;
    line-height: 0;
    padding: 0;
    background: transparent;
    border: none;
}
.round-btn {
    display: block;
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    padding: 7px 20px;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
    -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
    transition: all .3s cubic-bezier(.215,.61,.355,1);
}
.big-button{
    font-family: Helvetica Neue;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    font-size: 20px;
    padding: 7px 0;
    width: 100%;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.other-btn-con{
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    text-align: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    padding-top: 52px;
    padding-left: 8px;
    padding-right: 15px;
}
.text-title{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #fff;
}
.number-title {
    font-family: Helvetica Neue;
    font-weight: lighter;
    font-size: 91px;
    text-align: center;
    color: #fff;
}
.secend-line{
    padding: 20px 10px;
}
.flex-align-con {
    display: flex;
    -webkit-box-align: center;
}
.text-title{
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #fff;
}
.number-title{
    font-family: Helvetica Neue;
    font-weight: lighter;
    font-size: 91px;
    text-align: center;
    color: #fff;
}
.flex-align-con {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.first-line {
    border-top: 1px #fff solid;
    border-bottom: 1px #fff solid;
    padding: 20px 10px;
    padding-right: 0;
}
.detail_lesson{
    height: 30vh;
    /* border-top: 1px solid #fff; */
    position: fixed;
    top: 7.5vh;
    left: 0;
    width: 100%;
    z-index: 990;
}
  /*alert 成功弹出框样式*/
.el-message--success {
  top: 80px !important;
}
 
.el-message .el-icon-success {
  font-size: 18px;
}
 
.el-message--success .el-message__content {
  font-size: 18px;
  font-weight: 700;
}
</style>
<script>
import Header from "../Header";
import {apiUserUpdateCourseCompletions,apiCoursesDetail} from "@/request/api";
export default {
  components:{Header},
  data(){
    return {
        unlike_btn:false,
        like_btn:false,
        userCourseCompletion_id:0,
    }
  },
  created(){
      let id = this.$route.params.id;

    // 課堂詳情
    if(id && id > 0){
      apiCoursesDetail(id,{
        lang_id:this.$store.state.lang_id,
        user_id:this.$store.state.user_id,
      }).then(res=>{
        if(res.code == 200 && res.data){
            if(res.data.userCourseCompletion){
                this.userCourseCompletion_id = res.data.userCourseCompletion.id;
                if(res.data.userCourseCompletion.comment == 1){
                    this.unlike_btn = true;
                    this.like_btn = false;
                }else if(res.data.userCourseCompletion.comment == 2){
                    this.unlike_btn = false;
                    this.like_btn = true;
                }
            }else{
                if(res.data.user_is_completion == 1){
                    this.unlike_btn = true;
                    this.like_btn = false;
                }else if(res.data.user_is_completion == 2){
                    this.unlike_btn = false;
                    this.like_btn = true;
                }
            }
        }
      }).catch(error=>{
        console.log(error);
      })
    }
  },
  methods:{
      unlike_btns(val){
          let id = this.userCourseCompletion_id;
          if(id && id>0){
            apiUserUpdateCourseCompletions(id,{
                comment:val
            }).then(res=>{
                if(res.code == 200){
                    this.$message.success({message: "感謝您的評價!", duration: 10000});

                    if(val == 1){
                        this.unlike_btn = true;
                        this.like_btn = false;
                    }else if(val == 2){
                        this.unlike_btn = false;
                        this.like_btn = true;
                    }
                }else{
                    this.$message.success({message: res.message, duration: 10000});
                }
            })
          }
      }
  }
}
</script>

